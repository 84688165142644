import React from 'react';
import {Route} from "react-router-dom";
import {Container, Paper, Typography} from '@material-ui/core';
import {withStyles} from "@material-ui/styles";

const styles = theme => ({
    titlePaper: {
        maxWidth: '100%',
        margin: 'auto',
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2)
    },
});

const Layout = ({classes, children, content, pageTitle, ...rest}) => {
    return (
        <Route {...rest} render={props => (
            <>
                <Container className={classes.root}>
                    <Paper className={classes.titlePaper}>
                        <Typography variant={'h4'} align={'center'}>
                            <b>{pageTitle}</b>
                        </Typography>
                        {content &&
                        <Typography align={'center'}>
                            {content}
                        </Typography>
                        }
                    </Paper>
                </Container>
                {children}
            </>
        )}/>
    )
};

export default withStyles(styles)(Layout);
