import React, {useState} from 'react';
import {Paper} from '@material-ui/core';
import {withStyles} from "@material-ui/styles";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import {ChevronRight} from '@material-ui/icons';
import {ErrorSnackbar, SuccessSnackbar} from './SnackbarWrapper'
import clsx from "clsx";
import {pushState} from "./helpers";

const styles = theme => ({
    inputPaper: {
        padding: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        width: '70%',
        margin: 'auto',
    },
    inputPaperError: {
        boxShadow: '0 0 0 1px red'
    },
    input: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: theme.spacing(1),
    },
    snackbar: {
        width: 'auto',
    },
});
const CodeInput = ({advState, classes}) => {
    const [input, setInput] = useState('');
    const [inputError, setInputError] = useState(false);
    const [snackbar, setSnackbar] = useState({success: false, error: false});
    const handleSubmit = (e) => {
        e.preventDefault();
        switch (input.toLowerCase()) {
            case 'cousin':
                pushState({...advState, quizAvailable: true});
                break;
            case '🍼👶🤰':
                pushState({...advState, scavengerAvailable: true});
                break;
            case 'crib':
                pushState({...advState, thirdAvailable: true});
                break;
            case 'completequiz!':
                pushState({...advState, quizComplete: true});
                break;
            case 'completescavenger!':
                pushState({...advState, scavengerComplete: true});
                break;
            case 'completethird!':
                pushState({...advState, thirdComplete: true});
                break;
            case 'completeall!':
                pushState({...advState, thirdComplete: true, scavengerComplete: true, quizComplete: true});
                break;
            case 'openall!':
                pushState({...advState, quizAvailable: true, scavengerAvailable: true, thirdAvailable: true});
                break
            case 'resetall!':
                pushState({
                    ...advState,
                    quizComplete: false,
                    scavengerComplete: false,
                    thirdComplete: false,
                    quizAvailable: false,
                    scavengerAvailable: false,
                    thirdAvailable: false
                });
                break;
            default:
                setInputError(true);
                setSnackbar({success: false, error: true});
                setInput('');
                return;
        }
        setInput('');
        setSnackbar({error: false, success: true})
    };
    return (
        <>
            <form onSubmit={handleSubmit}>
                <Paper className={inputError
                    ?
                    clsx([classes.inputPaper, classes.inputPaperError])
                    :
                    classes.inputPaper}>
                    <InputBase
                        className={classes.input}
                        placeholder={inputError
                            ? "TRY AGAIN"
                            : "READY"}
                        inputProps={{'aria-label': 'enter guess'}}
                        value={input}
                        onChange={(e) => {
                            if (inputError) {
                                setInputError(false);
                            }
                            setInput(e.target.value);
                        }}
                    />
                    <Divider orientation={'vertical'} className={classes.divider}/>
                    <IconButton type='submit' color={inputError ? 'secondary' : 'primary'}
                                className={classes.iconButton} aria-label="directions">
                        <ChevronRight/>
                    </IconButton>
                </Paper>
                <SuccessSnackbar setSnackbar={setSnackbar} open={snackbar.success} text={"SUCCESS!!"}/>
                <ErrorSnackbar setSnackbar={setSnackbar} open={snackbar.error} text={"Invalid code."}/>
            </form>
        </>
    )
};
export default withStyles(styles)(CodeInput);
