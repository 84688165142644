import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Redirect} from 'react-router-dom';
import {Button, Paper, Radio, Typography} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import {withStyles} from "@material-ui/styles";
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Layout from "../components/Layout";
import {pushState} from "../components/helpers";
import ChallengeComplete from "../components/ChallengeComplete";


const styles = theme => ({
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    quizPaper: {
        width: '75%',
        padding: theme.spacing(2),
        margin: `${theme.spacing(4)}px auto`
    },
    youWin: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
    }
});

const Quiz = ({classes, advState}) => {
    const [questionOrder, setQuestionOrder] = useState([0, 1, 2, 3, 4]);
    const [questionNumber, setQuestionNumber] = useState(0);
    const [answerOrder, setAnswerOrder] = useState([0, 1, 2, 3]);
    const [answerSelect, setAnswerSelect] = useState('');
    const [timer, setTimer] = useState(0);
    const timerRef = useRef();
    const [failState, setFailState] = useState({value: false, reason: ""});
    const [success, setSuccess] = useState(false);
    const [goHome, setGoHome] = useState(false);
    const timePerQuestion = 7000;
    const restartTime = 3000;
    useEffect(() => {
        return () => {
            console.log("Unmounted!");
            clearTimeout(timerRef.current);
            console.log(timerRef.current)
        };
    }, [timerRef]);
    useEffect(() => {
        timerRef.current = timer;
    }, [timer]);
    const quiz = [
        {
            question: "What is normally the first sign of pregnancy?",
            answers: [ // Correct answer for each question is answers[0]
                "Missed period",
                "Cravings",
                "Fatigue",
                "Morning sickness"
            ],
        },
        {
            question: "What does the term 'Vernix' refer to?",
            answers: [ // Correct answer for each question is answers[0]
                "Greasy substance that covers the newborn baby.",
                "A complication of childbirth.",
                "The lining of the uterus.",
                "A baby's first poop.",
            ],
        },
        {
            question: "What are false labor contractions known as?",
            answers: [ // Correct answer for each question is answers[0]
                "Braxton Hicks",
                "Back labor",
                "Active labor",
                "Robert Higgs contractions",
            ],
        },
        {
            question: "What are the soft spots on a baby's head called?",
            answers: [ // Correct answer for each question is answers[0]
                "Fontanelles",
                "Fundus",
                "Pica",
                "Cerclages"
            ],
        },
        {
            question: "How much does a woman's blood volume increase during pregnancy?",
            answers: [ // Correct answer for each question is answers[0]
                "40 - 50%.",
                "10 - 20%",
                "75 - 100%",
                "Blood volume actually decreases."
            ],
        },
    ];
    const shuffle = (len) => {
        let array = [];
        if (len === 4) {
            array = [0, 1, 2, 3]
        } else {
            array = [0, 1, 2, 3, 4]
        }
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * array.length);
            const temp = array[i];
            array[i] = array[j];
            array[j] = temp
        }
        return array;
    };
    const handleChange = (event) => {
        setAnswerSelect(parseInt(event.target.value));
    };
    const failWrongAnswer = () => {
        setAnswerSelect('');
        setFailState({value: true, reason: "Wrong answer"});
        const timeout = setTimeout(restart, restartTime);
        setTimer(timeout);
    };
    const restart = useCallback(() => { // shitty workaround to avoid circular dependencies
        const failByTime = () => {
            setAnswerSelect('');
            setFailState({value: true, reason: "You took too long"});
            const timeout = setTimeout(restart, restartTime);
            setTimer(timeout);
        };
        setQuestionNumber(0);
        let array = shuffle(4);
        setAnswerOrder(array);
        array = shuffle(5);
        setQuestionOrder(array);
        setFailState(false);
        const timeout = setTimeout(failByTime, timePerQuestion);
        setTimer(timeout);
    }, []);
    useEffect(() => {
        restart();
    }, [restart]);
    const nextQuestion = () => {
        clearTimeout(timer);
        if (questionNumber === 4) {
            pushState({...advState, quizComplete: true});
            setSuccess(true);
            setTimeout(() => setGoHome(true), 5000)
        } else {
            const array = shuffle(4);
            setAnswerOrder(array);
            setQuestionNumber(questionNumber + 1);
            const timeout = setTimeout(failWrongAnswer, timePerQuestion);
            setTimer(timeout);
        }
    };
    const submit = (answerSelect) => {
        if (answerSelect === 0) {
            setAnswerSelect('');
            nextQuestion();
        } else {
            clearTimeout(timer);
            setAnswerSelect('');
            failWrongAnswer();
        }
    };
    if (goHome) {
        return <Redirect to='/'/>
    } else if (success) {
        return <ChallengeComplete challenge={"Quiz"}/>
    } else {
        return (<Layout pageTitle={'THE GREAT BABY KNOWLEDGE SHOWDOWN'}
                        content={"Now begins a true test of your wits. But... beware! Take too long, and you'll get the quits..."}>
                <Paper className={classes.quizPaper}>
                    {
                        failState.value
                            ?
                            <Typography>{failState.reason}! Restarting quiz.</Typography>
                            :
                            <>
                                <Typography
                                    variant={"h5"}>Question: {quiz[questionOrder[questionNumber]].question}
                                </Typography>
                                <FormControl component="fieldset">
                                    <RadioGroup aria-label="answers" name="answers" value={answerSelect}
                                                onChange={handleChange}>
                                        {
                                            answerOrder.map((index) => {
                                                return <FormControlLabel key={index} value={index}
                                                                         control={<Radio/>}
                                                                         label={quiz[questionOrder[questionNumber]].answers[index]}/>
                                            })
                                        }
                                    </RadioGroup>
                                    <Button variant="contained" color="primary"
                                            onClick={() => submit(answerSelect)}>
                                        Submit
                                        <SendIcon className={classes.rightIcon}/>
                                    </Button>
                                </FormControl>
                            </>
                    }
                </Paper>
            </Layout>
        )
    }
};

export default withStyles(styles)(Quiz);
