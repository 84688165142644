import React, {useEffect, useState} from 'react';
import Pusher from 'pusher-js';
import {BrowserRouter as Router, Route} from "react-router-dom";
import Home from "./routes/Home";
import Quiz from './routes/Quiz';
import Third from './routes/Third';
import {makeStyles} from "@material-ui/styles";
import Scavenger from "./routes/Scavenger";
import Admin from "./routes/Admin";


const useStyles = makeStyles(theme => ({
    root: {
        background: 'linear-gradient(to right, #C06C84, #6C5B7B, #355C7D)', /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        minHeight: '100vh',
        maxHeight: '100%',
        padding: theme.spacing(2),
        margin: 0,
    }
}));

const App = () => {
    const [advState, setAdvState] = useState({});
    const classes = useStyles();
    useEffect(() => {
        const pusher = new Pusher('5a787649041f5450e9e5', {
            cluster: 'us3',
            forceTLS: true
        });
        const channel = pusher.subscribe('gender-reveal');
        channel.bind('set-state', data => {
            setAdvState(prevState => {
                return {...prevState, ...data}
            })
        });
        fetch('/api/getState')
            .then(response => {
                return response.json();
            })
            .then(response => {
                console.log("getState response = ", response)
                setAdvState(response)
            })
    }, []);
    return (
        <div className={classes.root}>
            <Router>
                <Route path={'/'} exact render={(props) => <Home {...props} advState={advState}/>}/>
                <Route path={'/quiz'} exact render={(props) => <Quiz {...props} advState={advState}/>}/>
                <Route path={'/scavenger'} exact render={(props) => <Scavenger {...props} advState={advState}/>}/>
                <Route path={'/third'} exact render={(props) => <Third {...props} advState={advState}/>}/>
                <Route path={'/admin'} exact render={(props) => <Admin {...props} advState={advState}/>}/>
            </Router>
        </div>
    )
        ;
};

export default App;
