export const pushState = (state) => {
    console.log("pushing state: ", state)
    fetch('/api/setState',
        {
            method: 'PUT',
            body: JSON.stringify(state),
            headers: {
                "Content-Type": "application/json",
                "Accept": 'application/json'
            },
        }).then(() => {
        return true
    });
};
