import React from 'react';
import {Grid} from '@material-ui/core';
import {withStyles} from "@material-ui/styles";
import Challenge from "../components/Challenge";

const styles = theme => ({
    root: {
        marginTop: theme.spacing(2)
    }
});

const Challenges = ({classes, advState}) => {
    return (
        <Grid container component={'div'} justify={'center'} alignItems={'center'} spacing={3} className={classes.root}>
            {advState.quizAvailable ?
                <Grid item sm={3} xs={9} component={'div'}>
                    <Challenge
                        challenge={"Quiz"}
                        className={classes.flexItem}
                        complete={advState.quizComplete}
                        link={'/quiz'}
                        header={'QUESTIONS AND RIDDLES LIE BEFORE YOU'}
                        content={"ANSWER THEM QUICKLY SO YOU CAN BREAK THROUGH"}
                    />
                </Grid> : <></>}
            {advState.scavengerAvailable ?
                <Grid item sm={3} xs={9} component={'div'}>
                    <Challenge
                        challenge={"Scavenger Hunt"}
                        className={classes.flexItem}
                        complete={advState.scavengerComplete}
                        link={'/scavenger'}
                        header={'SECRETS HIDDEN BEFORE YOUR EYES'}
                        content={'SOLVE THE MYSTERY OR MEET YOUR DEMISE'}
                    />
                </Grid> : <></>}
            {advState.thirdAvailable ?
                <Grid item sm={3} xs={9} component={'div'}>
                    <Challenge
                        challenge={"Video Game"}
                        className={classes.flexItem}
                        complete={advState.thirdComplete}
                        link={'/third'}
                        header={'YOUR SKILL MUST NOT GO UNTESTED'}
                        content={"BEFORE THIS ADVENTURE IS COMPLETELY BESTED"}
                    />
                </Grid> : <></>}
        </Grid>
    )
};

export default withStyles(styles)(Challenges);
