import React from 'react';
import {withStyles} from "@material-ui/styles";
import CodeInput from "../components/CodeInput";
import Challenges from "../containers/Challenges";
import Layout from "../components/Layout";
import {CompleteText} from "../components/CompleteText";

const styles = theme => ({});

const Home = ({classes, advState}) => {
    if (advState.quizComplete && advState.scavengerComplete && advState.thirdComplete) {
        return <CompleteText/>
    } else {
        return (
            <Layout pageTitle={'THE AMAZING GENDER REVEAL ADVENTURE'}
                    content={"Starring... YOU! Mwahahahaha!"}>
                {!(advState.quizAvailable && advState.scavengerAvailable && advState.thirdAvailable) && <CodeInput advState={advState}/>}
                <Challenges advState={advState}/>
            </Layout>
        )
    }
};

export default withStyles(styles)(Home);
