import React from 'react';
import {Button, Card, CardActions, CardContent, Paper, Typography} from '@material-ui/core';
import {withStyles} from "@material-ui/styles";
import {Link} from "react-router-dom";
import clsx from "clsx";

const styles = theme => ({
    card: {
        padding: theme.spacing(2),
        textAlign: 'center'
    },
    complete: {
        boxShadow: '0 0 0 1px green'
    },
    content: {
        padding: 0,
        textAlign: 'center'
    },
    button: {
        margin: 'auto'
    }
});

const Challenge = ({challenge, classes, complete, link, header, content}) => {
    if (complete) {
        return (
            <Paper className={classes.card}>
                <Typography variant={"subtitle1"}>
                    <b>{challenge.toUpperCase()} CHALLENGE COMPLETE.</b>
                </Typography>
            </Paper>
        )
    } else {
        return (
            <Card className={clsx(complete ? [classes.card, classes.complete] : classes.card)}>
                <CardContent className={classes.content}>
                    <Typography variant={'h5'} gutterBottom={true}>
                        <b>{challenge} Challenge</b>
                    </Typography>
                    <Typography variant={'h5'} gutterBottom={true}>
                        {header}
                    </Typography>
                    <Typography variant={'body2'} gutterBottom={true}>
                        {content}
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button variant={'contained'} color={'primary'} component={Link} to={link}
                            className={classes.button}
                            disabled={complete}>
                        <Typography align={'center'}>
                            ATTEMPT CHALLENGE
                        </Typography>
                    </Button>
                </CardActions>
            </Card>
        )
    }
};

export default withStyles(styles)(Challenge);
