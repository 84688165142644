import React, {useState} from 'react';
import {Redirect} from 'react-router-dom';
import {Divider, IconButton, InputBase, Paper} from '@material-ui/core';
import {ChevronRight} from '@material-ui/icons';
import {makeStyles} from "@material-ui/styles";
import Layout from "../components/Layout";
import {pushState} from "../components/helpers";
import {ErrorSnackbar, SuccessSnackbar} from "../components/SnackbarWrapper";
import ChallengeComplete from "../components/ChallengeComplete";

const useStyles = makeStyles(theme => ({
    inputPaper: {
        padding: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        width: '70%',
        margin: 'auto',
    },
    input: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        flex: 1,
    },
}));

const Scavenger = ({advState}) => {
    const [goHome, setGoHome] = useState(false);
    const [snackbar, setSnackbar] = useState({success: false, error: false});
    const [success, setSuccess] = useState(false);
    const [input, setInput] = useState('');
    const password = "Love's New Sibling";
    const classes = useStyles();
    const check = () => {
        if (input === password) {
            setSuccess(true);
            pushState({...advState, thirdComplete: true})
            setTimeout(() => setGoHome(true), 5000)
        } else {
            setSnackbar({success:false,  error:true})
        }
    };
    if (goHome) {
        return <Redirect to='/'/>
    } else if (success) {
        return <ChallengeComplete challenge={"Ghetto Video Game"}/>
    } else {
        return (
            <Layout pageTitle={'A looming device lies in wait'}
                    content={"Find out what's broken to challenge your fate"}>
                <div className={classes.root}>
                    <Paper className={classes.inputPaper}>
                        <InputBase
                            className={classes.input}
                            inputProps={{'aria-label': 'enter guess'}}
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                            placeholder={"Did you win yet?"}
                        />
                        <Divider orientation={'vertical'} className={classes.divider}/>
                        <IconButton onClick={check} className={classes.iconButton} aria-label="directions">
                            <ChevronRight/>
                        </IconButton>
                    </Paper>
                    <SuccessSnackbar setSnackbar={setSnackbar} open={snackbar.success} text={"SUCCESS!!"}/>
                    <ErrorSnackbar setSnackbar={setSnackbar} open={snackbar.error} text={"Incorrect code."}/>
                </div>
            </Layout>
        )
    }
};

export default Scavenger
