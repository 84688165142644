import React from 'react';
import CodeInput from "../components/CodeInput";
import Home from "./Home";

const Admin = ({advState}) => {
    return (
        <div style={{paddingTop: 16}}>
            <CodeInput advState={advState}/>
            <div style={{margin: '32px 0px', height: 3, background: 'black'}}/>
            <Home advState={advState}/>
        </div>
    )
};

export default Admin;
