import React from 'react';
import {withStyles} from "@material-ui/core";
import Layout from "./Layout";

const styles = theme => ({
    youWin: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
    }
});

const ChallengeComplete = ({challenge, classes}) => {
        return (
            <div className={classes.youWin}>
                <Layout pageTitle={`${challenge} challenge complete.`}/>
            </div>
        )
};

export default withStyles(styles)(ChallengeComplete);
