import React, {useState} from 'react';
import {Redirect} from 'react-router-dom';
import {Button, ListItem, ListItemText, Paper} from '@material-ui/core';
import {makeStyles} from "@material-ui/styles";
import Layout from "../components/Layout";
import {pushState} from "../components/helpers";
import {FixedSizeList} from 'react-window';
import {difference, isEqual} from 'lodash';
import {ErrorSnackbar} from "../components/SnackbarWrapper";
import ChallengeComplete from "../components/ChallengeComplete";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        justifyContent: 'flex-start',
        alignItems: 'center',
        alignContent: 'stretch',
    },
    img: {
        maxHeight: '70vh',
        maxWidth: '100%',
    },
    paper: {
        maxWidth: '90%',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
        display: 'inline-block',
    },
}));

const Scavenger = ({advState}) => {
    const [goHome, setGoHome] = useState(false);
    const [snackbar, setSnackbar] = useState({success: false, error: false});
    const [success, setSuccess] = useState(false);
    const classes = useStyles();
    const items = ["Butterfly - Orange", "Peanut Butter", "Knife", "Bone - Purple", "Rabbit", "Fork", "Lock", "Rubber Duckie - Blue", "Rubber Duckie - Pink", "Rubber Duckie - Plain", "Brush", "Cat - Yellow", "Cat - Black", "Chopsticks", "Leash", "Nutmeg", "Sunglasses", "Totoro", "PS4 Controller", "Shoe Horn", "Leaf Dish", "Cat - Pink", "Cat - Gray", "Cat - White/Purple", "Dragon", "Bone - White", "Spoon", "Honey", "Kukui Nut Lei", "Cup - Happy Face", "Highlighter - Yellow", "Highlighter - Pink", "Highlighter - Blue", "Scissors", "Sailboat", "Tom Brady", "Paprika", "Ball - Orange", "Naruto", "Butterfly - Pink", "Glasses - Purple", "Scotch Tape"].sort();
    const correct = ["Rubber Duckie - Plain","Cat - Black","Knife","Glasses - Purple"];
    const check = () => {
        if (correct.length !== selectedIndexes.length) {
            setSnackbar({success: false, error: true});
            return false
        }
        let selectedItems = [];
        for (var i = 0; i < selectedIndexes.length; i++) {
            selectedItems.push(items[selectedIndexes[i]])
        }
        if (isEqual(difference(correct, selectedItems), [])) {
            setSnackbar({error: false, success: true})
            setSuccess(true);
            pushState({...advState, scavengerComplete: true});
            setTimeout(() => setGoHome(true), 5000);
            return true;
        } else {
            setSnackbar({success: false, error: true})
            return false;
        }
        ;

    };
    const [selectedIndexes, setSelectedIndexes] = useState([]);
    const clickIndex = (clickedIndex) => {
        if (selectedIndexes.includes(clickedIndex)) {
            setSelectedIndexes(selectedIndexes.filter((index) => {
                return clickedIndex !== index
            }))
        } else {
            setSelectedIndexes([...selectedIndexes, clickedIndex])
        }
    };

    function Row(props) {
        const {style, index, data} = props;
        return (
            <ListItem style={style} button key={index} onClick={() => clickIndex(index)}
                      selected={selectedIndexes.includes(index)}>
                <ListItemText primary={data[index]}/>
            </ListItem>
        );
    }

    if (goHome) {
        return <Redirect to='/'/>
    } else if (success) {
        return <ChallengeComplete challenge={"Scavenger Hunt"}/>
    } else {
        return (
            <Layout pageTitle={'From where you are, what do you see?'}
                    content={"What's there for you was different for me..."}>
                <div className={classes.root}>
                    <Paper className={classes.paper}>
                        <a href={`${process.env.PUBLIC_URL}/cabinet.jpg`}>
                            <img alt={"Find the missing pieces."} src={`${process.env.PUBLIC_URL}/cabinet.jpg`}
                                 className={classes.img}/>
                        </a>
                    </Paper>
                    <Paper className={classes.paper}>
                        <FixedSizeList height={150} width={360} itemSize={46} itemCount={items.length}
                                       itemData={Array.from({length: items.length}, () => items).flat()}>
                            {Row}
                        </FixedSizeList>
                    </Paper>
                    <Button color='secondary' variant='contained' onClick={check}>Check</Button>
                </div>
                <ErrorSnackbar setSnackbar={setSnackbar} open={snackbar.error} text={"Incorrect selections."}/>
            </Layout>
        )
    }
};

export default Scavenger
