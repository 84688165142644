import React, {useEffect, useState} from 'react';
import {Typography} from '@material-ui/core';
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    child: {
        flex: 1
    }
}));

export const CompleteText = () => {
    const classes = useStyles();
    const textArray = ['!ALERT!', '', '!ALERT!', '', '15-25-39', '', '15-25-39', ''];
    const [index, setIndex] = useState(0);
    useEffect(() => {
        setTimeout(() => setIndex(index + 1), 500);
    });
    return (
        <div className={classes.root}>
            <Typography variant={'h1'} align={'center'} className={classes.child}
                        color={[2, 6].includes(index%textArray.length) ? 'primary' : 'secondary'}>
                {textArray[index % textArray.length]}
            </Typography>
        </div>

    )
};

